<template>
  <div class="container">
    <b-card class="m-4" border-variant="primary">
      <form-wizard
        color="#428517"
        nextButtonText="Próximo"
        backButtonText="Voltar"
        finishButtonText="Enviar"
        shape=""
        title="PRÉ CADASTRO"
        :subtitle="'Cadastro de imobiliárias'"
      >
        <!-- Botões de ações -->

        <div slot="footer" slot-scope="props" class="text-right">
          <b-button
            @click="props.prevTab()"
            v-if="props.activeTabIndex > 0"
            class="m-1"
            variant="warning"
            >Voltar</b-button
          >
          <b-button
            @click="props.nextTab()"
            variant="primary"
            v-if="!props.isLastStep"
            >Avançar</b-button
          >
          <b-button
            v-else
            variant="primary"
            v-b-modal.recaptcha
            @click="openModalConfirm()"
            >Finalizar</b-button
          >
        </div>

        <!-- IMOBILIária -->
        <tab-content
          title="Imobiliária"
          class="mb-2"
          :before-change="checkData"
        >
          <validation-observer ref="imobRules" tag="form">
            <b-row>
              <!-- RAZÃO SOCIAL corporatename -->
              <b-col cols="12" md="6">
                <b-form-group label="Razão Social" label-for="corporatename">
                  <validation-provider
                    #default="{ errors }"
                    name="corporatename"
                    rules="required"
                  >
                    <b-form-input
                      id="corporatename"
                      placeholder="Imobiliária LTDA"
                      v-model="cadastro.corporatename"
                      :state="errors.length > 0 ? false : null"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- CNPJ -->
              <b-col cols="12" md="6">
                <b-form-group label="CNPJ" label-for="CNPJ">
                  <validation-provider
                    #default="{ errors }"
                    name="CNPJ"
                    rules="required|min:18"
                  >
                    <b-form-input
                      placeholder="XX.XXX.XXX/0001-XX"
                      v-mask="'XX.XXX.XXX/XXXX-XX'"
                      v-model="cadastro.cnpj"
                      :state="errors.length > 0 ? false : null"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- NOME FANTASIA - NAME -->
              <b-col cols="12" md="6">
                <b-form-group label="Nome Fantasia" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="Minha imobiliária"
                      v-model="cadastro.name"
                      :state="errors.length > 0 ? false : null"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- CRECI PJ - crecipj -->
              <b-col cols="12" md="6">
                <b-form-group label="CRECI-PJ" label-for="crecipj">
                  <validation-provider
                    #default="{ errors }"
                    name="crecipj"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="2039-J"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.crecipj"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- EMAIL -->
              <b-col cols="12" md="6">
                <b-form-group label="E-mail" label-for="email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      placeholder="contato@imobiliaria.com.br"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.email"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- WEBSITE -->
              <b-col cols="12" md="6">
                <b-form-group label="Website" label-for="website">
                  <validation-provider
                    #default="{ errors }"
                    name="WebSite"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="www.imobiliaria.com.br"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.website"
                      required
                    />
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- ENDEREÇO - adress.local -->
              <b-col cols="12" md="9">
                <b-form-group label="Endereço (com n°)" label-for="adresslocal">
                  <validation-provider
                    #default="{ errors }"
                    name="adresslocal"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="Av. da Imobiliaria, 13"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.adress.local"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- ENDEREÇO - adress.cep -->
              <b-col cols="12" md="3">
                <b-form-group label="CEP" label-for="adresscep">
                  <validation-provider
                    #default="{ errors }"
                    name="adresscep"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="XXXXX-XXXX"
                      v-mask="'XXXXX-XXX'"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.adress.cep"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- ADRESS CIDADE - adress.cidade -->
              <b-col cols="12" md="6">
                <b-form-group label="Cidade" label-for="adresscidade">
                  <validation-provider
                    #default="{ errors }"
                    name="adresscidade"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="Cidade da Imobiliária"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.adress.cidade"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- BAIRRO - adress.bairro -->
              <b-col cols="12" md="6">
                <b-form-group label="Bairro" label-for="adressbairro">
                  <validation-provider
                    #default="{ errors }"
                    name="adressbairro"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="Bairro da imobiliária"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.adress.bairro"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- equipe - equipesize -->
              <b-col cols="12" md="6">
                <b-form-group label="QTO de Corretores" label-for="equipesize">
                  <validation-provider
                    #default="{ errors }"
                    name="equipesize"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="20"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.teamsize"
                      type="number"
                    >
                    </b-form-input>
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Atuação - action -->
              <b-col cols="12" md="6">
                <b-form-group label="Área de atuação" label-for="action">
                  <validation-provider
                    #default="{ errors }"
                    name="action"
                    rules="required"
                  >
                    <v-select
                      v-model="cadastro.action"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      placeholder="Selecione as opções"
                      label="title"
                      :options="option"
                    />

                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- website -->
              <b-col cols="12"> </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Representante legal -->
        <tab-content
          title="Sócio Administrador"
          class="mb-2"
          :before-change="checkRepresentanteData"
        >
          <p class="p-1 bg-warning text-white">
            É imprescindível o cadastro dos dados do Sócio Administrador para
            assinaturas de contratos para a parceria.
          </p>
          <validation-observer ref="representanteRules" tag="form">
            <b-row>
              <b-col cols="12" md="6">
                <!-- NOME -->
                <b-form-group label="Nome" label-for="ownername">
                  <validation-provider
                    #default="{ errors }"
                    name="ownername"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="Nome do proprietário"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.owner.name"
                      v-mask=""
                    />
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- PHONE -->
              <b-col cols="12" md="6">
                <b-form-group label="WhatsApp" label-for="ownerphone">
                  <validation-provider
                    #default="{ errors }"
                    name="ownerphone"
                    rules="required"
                  >
                    <b-form-input
                      placeholder="WhatsApp do proprietário"
                      v-mask="'(XX)XXXXX-XXXX'"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.owner.phone"
                    />
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- email -->
              <b-col cols="12" md="6">
                <b-form-group label="CPF" label-for="ownercpf">
                  <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    rules="required|min:14"
                  >
                    <b-form-input
                      placeholder="CPF do proprietário"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.owner.cpf"
                      v-mask="'XXX.XXX.XXX-XX'"
                      required
                    />
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group label="E-mail" label-for="owneremail">
                  <validation-provider
                    #default="{ errors }"
                    name="Razão Social"
                    rules="required|email"
                  >
                    <b-form-input
                      placeholder="E-mail do proprietário"
                      :state="errors.length > 0 ? false : null"
                      v-model="cadastro.owner.email"
                      required
                    />
                    <small class="text-danger">{{
                      errorMessage(errors[0])
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <!-- Representante -->
        <tab-content
          title="Gerente para o produto"
          class="mb-2"
          :before-change="checkLastData"
        >
          <p class="p-1 bg-warning text-white">
            Adicione aqui o(s) seu(s) gerente(s) para o produto. Todos os N°
            cadastrados aqui receberão nossos comunicados via whatsapp / E-mail
          </p>
          <validation-observer ref="lastRules" tag="form">
            <b-row v-for="(gerente, index) in gerentes" :key="gerente.id">
              <b-col md="3">
                <b-form-group label="Nome">
                  <b-form-input
                    type="text"
                    placeholder="Nome"
                    v-model="gerente.name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="E-mail">
                  <b-form-input
                    type="text"
                    placeholder="E-mail"
                    v-model="gerente.email"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Whatsapp">
                  <b-form-input
                    type="text"
                    placeholder="Whatsapp"
                    v-mask="'(XX)XXXXX-XXXX'"
                    v-model="gerente.phone"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button variant="danger" @click="removeGerente(index)">
                  X
                </b-button>
              </b-col>
            </b-row>
            <b-button variant="primary" @click="newGerente">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Adicionar gerente</span>
            </b-button>
          </validation-observer>
        </tab-content>
      </form-wizard>
      <!-- RECAPTCHA -->
      <b-modal
        id="recaptcha"
        centered
        ok-title="Enviar"
        cancel-title="Cancelar"
        @ok="checkRecaptcha()"
        title="Sistema Anti-Spam"
      >
        <b-card-text class="text-center">
          <h2>{{ numberOne }} + {{ numberTwo }} =</h2>
          <b-form-input type="number" v-model="answer" />
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "vee-validate/dist/rules.umd.js";
extend("required", required);
extend("min", min);
extend("email", email);

import { mask } from "vue-the-mask";

import axios from "axios";
import { baseApiUrl } from "@/global";

import Ripple from "vue-ripple-directive";

export default {
  props: {
    produto: {
      type: Object,
    },
  },
  directives: { mask, Ripple },
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      option: [{ title: "Aluguel" }, { title: "Vendas" }],
      cadastro: {
        active: true,
        status: {
          id: 1,
          name: "Análise",
        },
        date: "",
        corporatename: "",
        cnpj: "",
        name: "",
        adress: {},
        owner: {},
        answerable: {},
        history: [],
      },
      numberOne: 0,
      numberTwo: 0,
      answer: "",
      result: 0,
      gerentes: [],
    };
  },
  methods: {
    openModalConfirm() {
      this.numberOne = Math.floor(Math.random() * 10);
      this.numberTwo = Math.floor(Math.random() * 10);
      this.result = this.numberOne + this.numberTwo;
    },
    checkRecaptcha() {
      if (this.answer == this.result) {
        this.enviar();
        this.$emit("setDone");
      } else {
        this.$swal({
          title: "Oops... !",
          text: "Sistema anti-spam retornou com erro",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    async enviar() {
      let now = new Date();
      this.cadastro.date = Math.floor(now / 1000);

      let cadastroId;
      //salva o cadastro
      await axios
        .post(`${baseApiUrl}/cadastrar`, {
          db: this.produto.db,
          register: this.cadastro,
          gerentes: this.gerentes
        })
        .then((res) => {
          cadastroId = res.data.id;
        });


      //cria o histórico
      let historico = {
        variant: "success",
        title: "Cadastro",
        subtitle: `${this.cadastro.name} se cadatrou`,
        day: Math.floor(now / 1000),
        time: `${(now.getDate() < 10 ? "0" : "") + now.getDate()}/${
          now.getMonth() + 1
        }/${now.getFullYear()} às ${now.getHours()}:${
          (now.getMinutes() < 10 ? "0" : "") + now.getMinutes()
        }`,
      };

      //salve histórico
      await axios.put(`${baseApiUrl}/historico`, {
        db: this.produto.db,
        _id: cadastroId,
        historico,
      });
    },
    checkData() {
      return new Promise((resolve, reject) => {
        this.$refs.imobRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkRepresentanteData() {
      return new Promise((resolve, reject) => {
        this.$refs.representanteRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    checkLastData() {
      return new Promise((resolve, reject) => {
        this.$refs.lastRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    errorMessage(err, msg) {
      if (err) {
        if (msg) return msg;
        return "Informação necessária para prosseguir.";
      }
    },
    newGerente() {
      this.gerentes.push({
        name: "",
        phone: "",
        email: "",
      });
    },
    removeGerente(index) {
      this.gerentes.splice(index, 1);
    },
  },
  mounted() {
    // this.enviar();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
 